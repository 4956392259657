import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import Container from "react-bootstrap/Container"
import Socials from "../components/socials"

export default () => (
  <div>    
    <Header headerText="Home Page" />    
    <Container className="thanks">
      <p>Thank your for your request. We will get back to you as soon as possible.</p>
    </Container>   
    <Socials /> 
    <Footer />
  </div>
)
