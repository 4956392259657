import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import instagramLogo from '../../static/assets/instagram.svg'
import facebookLogo from '../../static/assets/facebook.svg'
import twitterLogo from '../../static/assets/twitter.svg'
import pinterestLogo from '../../static/assets/pinterest.svg'
import styles from './socials.module.scss'


export default () => (
  <StaticQuery
    query={graphql`
        query socialQuery {
          site {
            siteMetadata {
              social {
                facebookUrl
                instagramUrl
                twitterUrl   
                pinterestUrl 
              }
            }
          }
        }        
      `}
    render={data => (
      <div className={styles.block}>
        <Row noGutters>
          <Col>
            {data.site.siteMetadata.social.instagramUrl && (
              <a href={data.site.siteMetadata.social.instagramUrl} target="_blank" rel="noreferrer">
                <img src={instagramLogo} alt='Instagram Icon' className={styles.logo}/>
              </a>
            )}                    
            {data.site.siteMetadata.social.facebookUrl && (
              <a href={data.site.siteMetadata.social.facebookUrl} target="_blank" rel="noreferrer">
                <img src={facebookLogo} alt='Facebook Icon' className={styles.logo}/>
              </a>        
            )}
            {data.site.siteMetadata.social.twitterUrl && (
              <a href={data.site.siteMetadata.social.twitterUrl} target="_blank" rel="noreferrer">
                <img src={twitterLogo} alt='Twitter Icon' className={styles.logo}/>
              </a>        
            )}     
            {data.site.siteMetadata.social.pinterestUrl && (
              <a href={data.site.siteMetadata.social.pinterestUrl} target="_blank" rel="noreferrer">
                <img src={pinterestLogo} alt='Pinterest Icon' className={styles.logo}/>
              </a>        
            )}          
          </Col>
        </Row>
      </div>
    )}
  />
)
